<template>
  <div>
    <dynamic-template :name="mainTemplate"></dynamic-template>
    <div class="left-sticky">
      <dynamic-template name="Logo"></dynamic-template>
      <dynamic-template name="Weather1"></dynamic-template>
      <dynamic-template name="Currency1"></dynamic-template>
    </div>
    <dynamic-template name="FooterBar"></dynamic-template>
  </div>
</template>

<script>
import DynamicTemplate from '../components/DynamicTemplate';

export default {
  name: 'layout2',
  props: ['data'],
  components: { DynamicTemplate },
  computed: {
    mainTemplate() {
      return this.$store.state.mainTemplate;
    }
  }
};
</script>

<style lang="scss">
.left-sticky {
  position: fixed;
  top: 0;
  left: 0;
  background: url('/images/parts/left-sticky-bg.png') center top no-repeat;
  background-size: cover;
  width: 239px;
  height: 1022px;
  padding-right: 84px;
  z-index: 5;
  .logo {
    margin: 16px -59px 5px 22px;
    img {
      width: 195px;
      height: 195px;
      -o-object-fit: contain;
      object-fit: contain;
      object-position: center;
    }
  }
}
@media (max-width: 1365px) {
  .left-sticky {
    width: 196px;
    height: 100%;
    padding-right: 68px;
    background-size: 100% auto;
    .logo {
      margin: 10px -46px 0 16px;
      img {
        width: 162px;
        height: 162px;
      }
    }
  }
}
@media (min-width: 1366px) and (max-width: 1900px) {
}
@media (min-width: 1950px) and (max-width: 2048px) {
}
@media (min-width: 2049px) {
  .left-sticky {
    width: 472px;
    height: 100%;
    padding-right: 165px;
    background-size: 100% auto;
    .logo {
      margin: 30px -96px 20px 30px;
      img {
        width: 364px;
        height: 364px;
      }
    }
  }
}
.pharmacy {
  .main {
    right: 10px;
    left: 160px;
    .main-content {
      .item {
        max-width: 872px;
      }
    }
  }
}
</style>
